import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchJwtToken } from 'actions/admin/secure_messaging';

const SecureMessaging = props => {
  const handleFetchJwtToken = async () => {
    const response = await props.fetchJwtToken();
    return response.token;
  };

  useEffect(() => {
    let isScriptLoaded = false;
    const messengerId = '882a50f8-e2fe-4a79-9d31-163789d255f6';

    const loadComm100 = async () => {
      const token = await handleFetchJwtToken();
      const existingScript = document.getElementById(
        `secure-message-portal-${messengerId}`,
      );

      // Ensure the global object exists
      window.Comm100SecureMessaging = window.Comm100SecureMessaging || {};
      // Set the document property to the desired container
      window.Comm100SecureMessaging.document = document.getElementById(
        'comm100-secure-messaging',
      );

      if (!existingScript) {
        const g = {
          siteId: '80000136',
          messengerId,
          thirdPartyUserJWTToken: token,
          loadStatus: 'notStart',
          vueDomainServer: 'https://vue.comm100.com',
          controlPanelUrl: 'https://dash13.comm100.io',
          apiServerUrl: 'https://api13.comm100.io',
          document: document.querySelector(
            `script[data-messengerid='${messengerId}']`,
          ),
        };
        window.Comm100SecureMessaging = g;

        if (g.loadStatus !== 'notStart') {
          return;
        }

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.defer = true;
        s.id = `secure-message-portal-${messengerId}`;
        s.src = `https://vue.comm100.com/frontend/securemessaging/index.js?v=${new Date().valueOf()}`;
        s.dataset.messengerid = messengerId;
        const b = document.getElementsByTagName('body')[0];
        b.insertBefore(s, b.firstChild);

        isScriptLoaded = true;
      }
    };

    loadComm100();

    return () => {
      if (isScriptLoaded) {
        delete window.Comm100SecureMessaging;
        const script = document.getElementById(
          `secure-message-portal-${messengerId}`,
        );
        if (script) {
          script.remove();
        }
      }
    };
  }, []);

  return <div id="comm100-secure-messaging"></div>;
};

SecureMessaging.propTypes = {
  fetchJwtToken: PropTypes.func.isRequired,
};

export default connect(null, { fetchJwtToken })(injectIntl(SecureMessaging));
