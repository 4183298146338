import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { headers } from '../../../actions/defaults';
import { API_URL } from '../../../environment';

const useStyles = makeStyles(theme => ({
  button: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    '&:focus': {
      color: theme.palette.primary.contrastText,
    },
    '&:active': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const StripeLoginButton = () => {
  const classes = useStyles();

  const [stripeLoginLink, setStripeLoginLink] = useState(null);

  useEffect(() => {
    axios
      .post(`${API_URL}/clinic_admin/stripe_login_links`, {}, headers())
      .then(response => {
        setStripeLoginLink(response.data.url);
      });
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        href={stripeLoginLink}
        rel="noopener noreferrer"
        className={classes.button}
      >
        <FormattedMessage defaultMessage="Go To Stripe" />
      </Button>
    </div>
  );
};

export default injectIntl(StripeLoginButton);
