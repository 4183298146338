/* eslint-disable formatjs/no-literal-string-in-jsx */
/* eslint-disable react/jsx-no-literals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../environment';
class Redirect extends Component {
  componentDidMount() {
    const id = this.props.match.params.appointment_id;

    localStorage.clear();
    localStorage.setItem('token', this.props.match.params.token);
    setTimeout(
      () =>
        window.location.replace(
          `${ROOT_URL}/sessions/clients/video_call/${id}`,
        ),
      500,
    );
  }

  render() {
    const id = this.props.match.params.appointment_id;
    return (
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <p style={{ fontSize: '40px' }}>
          Click{' '}
          <a href={`${ROOT_URL}/sessions/clients/video_call/${id}`}>here</a> if
          you are not automatically directed in 5 seconds...
        </p>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

Redirect.propTypes = {
  match: PropTypes.object.isRequired,
};

Redirect.defaultProps = {};

export default connect(mapStateToProps)(Redirect);
