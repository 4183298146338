import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { headers } from '../../../actions/defaults';
import { API_URL } from '../../../environment';

const useStyles = makeStyles(theme => ({
  outerBox: {
    padding: '30px',
    borderRadius: '8px',
  },
  listItem: {
    marginBottom: '12px',
    padding: '16px',
    borderBottom: '1px solid #e0e0e0',
  },
  gridContainer: {
    alignItems: 'center',
  },
  gridItem: {
    textAlign: 'left',
  },
  buttonGroup: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  input: {
    fontSize: '16px',
    color: 'black',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  button: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    '&:focus': {
      color: theme.palette.primary.contrastText,
    },
    '&:active': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const TaxInformation = ({ clinicAdminData, history }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [formData, setFormData] = useState({
    is_tax_exempt: null,
    gst_hst_yn: null,
    gst_hst_number: null,
    pst_number: null,
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (clinicAdminData?.clinic?.id) {
      setLoading(false);
      setFormData({
        is_tax_exempt: clinicAdminData.clinic.is_tax_exempt ? 'true' : 'false',
        gst_hst_yn: clinicAdminData.clinic.gst_hst_number ? 'true' : 'false',
        gst_hst_number: clinicAdminData.clinic.gst_hst_number || '',
        pst_number: clinicAdminData.clinic.pst_number || '',
      });
    } else {
      setLoading(true);
    }
  }, [clinicAdminData]);

  const handleInputChange = useCallback(event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setUpdating(true);
    setError(null);
    setValidationErrors(prevErrors => ({
      ...prevErrors,
      [name]: null,
    }));
  }, []);

  const handleSnackClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const validateForm = () => {
    const errors = {};
    const gstHstPattern = /^\d{9}[A-Za-z]{2}\d{4}$/i;
    if (
      formData.gst_hst_yn === 'true' &&
      formData.gst_hst_number &&
      !gstHstPattern.test(formData.gst_hst_number)
    ) {
      errors.gst_hst_number = (
        <FormattedMessage defaultMessage="Your HST or GST number must have the format 123456789RT0001" />
      );
    }

    if (formData.gst_hst_yn === 'true' && !formData.gst_hst_number) {
      errors.gst_hst_number = <FormattedMessage defaultMessage="Required" />;
    }

    if (formData.is_tax_exempt === null) {
      errors.is_tax_exempt = <FormattedMessage defaultMessage="Required" />;
    }

    if (formData.gst_hst_yn === null) {
      errors.gst_hst_yn = <FormattedMessage defaultMessage="Required" />;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setSubmitting(true);
    try {
      const params = {
        clinic: {
          is_tax_exempt: formData.is_tax_exempt,
          gst_hst_number:
            formData.gst_hst_yn === 'true' ? formData.gst_hst_number : null,
          pst_number:
            formData.gst_hst_yn === 'true' ? formData.pst_number : null,
        },
      };

      const response = await axios.put(
        `${API_URL}/clinic_admin/clinics`,
        params,
        headers(),
      );
      setSnackOpen(true);
      setFormData({
        is_tax_exempt: response.data.is_tax_exempt ? 'true' : 'false',
        gst_hst_yn: response.data.gst_hst_number ? 'true' : 'false',
        gst_hst_number: response.data.gst_hst_number || '',
        pst_number: response.data.pst_number || '',
      });
      setEditing(false);
      setUpdating(false);
      setError(null);
    } catch (err) {
      setError('Failed to update clinic data');
    } finally {
      setSubmitting(false);
    }
  };
  const renderListItem = (label, content) => (
    <ListItem className={classes.listItem}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="body1" color="textSecondary">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          {content}
        </Grid>
      </Grid>
    </ListItem>
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box className={classes.outerBox}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          <FormattedMessage defaultMessage="Tax information updated!" />
        </Alert>
      </Snackbar>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        <FormattedMessage defaultMessage="Tax Information" />
      </Typography>
      <form onSubmit={handleSubmit}>
        <List disablePadding>
          {renderListItem(
            <FormattedMessage defaultMessage="Are your clinical services tax exempt?" />,
            editing ? (
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="is_tax_exempt"
                  value={formData.is_tax_exempt}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={<FormattedMessage defaultMessage="Yes" />}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={<FormattedMessage defaultMessage="No" />}
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <Typography>
                {formData.is_tax_exempt === 'true' ? (
                  <FormattedMessage defaultMessage="Yes" />
                ) : (
                  <FormattedMessage defaultMessage="No" />
                )}
              </Typography>
            ),
          )}
          {validationErrors.is_tax_exempt && (
            <Typography className={classes.errorText}>
              {validationErrors.is_tax_exempt}
            </Typography>
          )}

          {renderListItem(
            <FormattedMessage defaultMessage="Are you registered to charge taxes for your services?" />,
            editing ? (
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="gst_hst_yn"
                  value={formData.gst_hst_yn}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={<FormattedMessage defaultMessage="Yes" />}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={<FormattedMessage defaultMessage="No" />}
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <Typography>
                {formData.gst_hst_yn === 'true' ? (
                  <FormattedMessage defaultMessage="Yes" />
                ) : (
                  <FormattedMessage defaultMessage="No" />
                )}
              </Typography>
            ),
          )}
          {validationErrors.gst_hst_yn && (
            <Typography className={classes.errorText}>
              {validationErrors.gst_hst_yn}
            </Typography>
          )}

          {formData.gst_hst_yn === 'true' && (
            <>
              {renderListItem(
                <FormattedMessage defaultMessage="HST or GST number (required)" />,
                <TextField
                  name="gst_hst_number"
                  value={formData.gst_hst_number}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!editing}
                  error={!!validationErrors.gst_hst_number}
                  InputProps={{
                    classes: { input: classes.input },
                  }}
                />,
              )}
              {validationErrors.gst_hst_number && (
                <Typography className={classes.errorText}>
                  {validationErrors.gst_hst_number}
                </Typography>
              )}

              {renderListItem(
                <FormattedMessage defaultMessage="PST, RST, or QST number (if applicable)" />,
                <TextField
                  name="pst_number"
                  value={formData.pst_number}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!editing}
                  InputProps={{
                    classes: { input: classes.input },
                  }}
                />,
              )}
            </>
          )}
        </List>
        <Box className={classes.buttonGroup}>
          {editing ? (
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!updating || submitting}
                className={classes.button}
              >
                {submitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <FormattedMessage defaultMessage="Save" />
                )}
              </Button>
              <Box ml={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setEditing(false);
                    setUpdating(false);
                    setValidationErrors({});
                    history.go(0);
                  }}
                  disabled={submitting}
                  className={classes.button}
                >
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
              </Box>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditing(true)}
              className={classes.button}
            >
              <FormattedMessage defaultMessage="Edit" />
            </Button>
          )}
        </Box>
      </form>
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

TaxInformation.propTypes = {
  clinicAdminData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(TaxInformation);
