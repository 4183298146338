import React from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles
import StripeLoginButton from './StripeLoginButton';
import StripeOnboardButton from './StripeOnboardButton';

const useStyles = makeStyles(theme => ({
  listItem: {
    marginBottom: '12px',
    padding: '16px',
    borderBottom: '1px solid #e0e0e0',
  },
  gridContainer: {
    alignItems: 'center',
  },
  gridItem: {
    textAlign: 'left',
  },
  outerBox: {
    padding: '30px',
    borderRadius: '8px',
  },
  button: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    '&:focus': {
      color: theme.palette.primary.contrastText,
    },
    '&:active': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const MyAccount = ({ clinicAdminData }) => {
  const classes = useStyles();
  const { clinic } = clinicAdminData;

  const renderListItem = (label, value) => (
    <ListItem className={classes.listItem}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="body1" color="textSecondary">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="body1" color="textPrimary">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );

  return (
    <Box className={classes.outerBox}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        <FormattedMessage defaultMessage="My Account" />
      </Typography>
      <List disablePadding>
        {renderListItem(
          <FormattedMessage defaultMessage="First Name" />,
          clinicAdminData.first_name,
        )}
        {renderListItem(
          <FormattedMessage defaultMessage="Last Name" />,
          clinicAdminData.last_name,
        )}
        {renderListItem(
          <FormattedMessage defaultMessage="Email" />,
          clinicAdminData.email,
        )}
        {renderListItem(
          <FormattedMessage defaultMessage="Clinic Token" />,
          clinic?.clinic_token,
        )}
        <ListItem className={classes.listItem}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={6} className={classes.gridItem}>
              <Typography variant="body1" color="textSecondary">
                <FormattedMessage defaultMessage="Stripe Onboarding" />
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              {clinic?.onboarded ? (
                <StripeLoginButton />
              ) : (
                <StripeOnboardButton />
              )}
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
};

MyAccount.propTypes = {
  clinicAdminData: PropTypes.object.isRequired,
};

export default injectIntl(MyAccount);
