import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { signinUser, verifyTwoFactor, getPin } from '../../../actions/auths';
import { fetchUser } from '../../../actions/user';

class VerifyPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      resentMessage: '',
    };
  }

  onSubmit = values => {
    this.props.verifyTwoFactor(values, () => this.props.fetchUser());
  };

  UNSAFE_componentWillMount() {
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    } else if (!this.props.two_factor) {
      this.props.history.push('/signin');
    }
  }

  handlePinInput = event => {
    const { name, value } = event.target;
    this.setState({
      resentMessage: '',
      [name]: value.slice(0, 7),
    });
  };

  resetPin = () => {
    const { email } = this.props;
    this.props.getPin({ email: email }, () => {
      this.setState({
        resentMessage: (
          <FormattedMessage defaultMessage="Your new pin was successfully sent." />
        ),
      });
    });
  };

  normalizePin = value => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    return `${onlyNums.slice(0, 7)}`;
  };
  render() {
    const { code, resentMessage } = this.state;
    const {
      pristine,
      submitting,
      email,
      handleSubmit,
      errorMessage,
    } = this.props;
    return (
      <div className="signin-container">
        <div className="row">
          <div className="col" style={{ minWidth: 376 }}>
            <div className="auth-form center-items">
              <h4>
                {' '}
                <FormattedMessage defaultMessage="Verify Access" />
              </h4>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="verify-wrapper">
                  <p className="auth-paragraph">
                    <FormattedMessage defaultMessage="An extra security layer has been added to ensure it's you" />
                  </p>
                  <p className="auth-paragraph">
                    <FormattedMessage defaultMessage="Enter the 7 digit pin sent to your email" />
                  </p>
                  <div className="pin-verify-input">
                    <Field
                      name="code"
                      component="input"
                      type="text"
                      value={code}
                      onChange={this.handlePinInput}
                      normalize={this.normalizePin}
                    />
                  </div>
                  <p className="signin-link-text">
                    <FormattedMessage defaultMessage="Didn't get the pin?" />{' '}
                    <span role="button" onClick={() => this.resetPin()}>
                      <FormattedMessage defaultMessage="Resend" />
                    </span>
                  </p>
                  {errorMessage && (
                    <p className="signin-link-text error-text">
                      {errorMessage}
                    </p>
                  )}
                  {resentMessage && (
                    <p className="signin-link-text success">{resentMessage}</p>
                  )}
                  <button
                    type="submit"
                    style={{ margin: '0 auto', cursor: 'pointer' }}
                    className="signin-button"
                  >
                    <FormattedMessage defaultMessage="Sign In" />
                  </button>
                  <br />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    two_factor: state.auth.two_factor,
    email: state.auth.email,
    log_id: state.auth.log_id,
    authenticated: state.auth.authenticated,
    errorMessage: state.auth.error,
    initialValues: {
      id: state.auth.log_id,
      email: state.auth.email,
    },
  };
}

VerifyPin.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  signinUser,
  fetchUser,
  verifyTwoFactor,
  getPin,
})(
  reduxForm({
    form: 'VerifyPinForm',
    enableReinitialize: true,
  })(VerifyPin),
);
