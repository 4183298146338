import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

function RequireAuth(ComposedComponent) {
  function Authentication(props) {
    const history = useHistory();

    useEffect(() => {
      if (!props.authenticated) {
        history.push('/signin');
      }
    }, [props]);

    /* eslint-disable react/jsx-props-no-spreading */
    return <ComposedComponent {...props} />;
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  Authentication.propTypes = {
    authenticated: PropTypes.bool,
  };

  Authentication.defaultProps = {
    authenticated: false,
  };

  return connect(mapStateToProps)(Authentication);
}

export default RequireAuth;
